import React from 'react';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { VimeoEntity } from '../../types/contentfulModels/VimeoEntity';

import * as styles from './ProjectList.module.scss';

import ProjectTileSmall from './ProjectTileSmall/ProjectTileSmall';
import ProjectTileMedium from './ProjectTileMedium/ProjectTileMedium';
import ProjectTileLarge from './ProjectTileLarge/ProjectTileLarge';

const projectTypes = {
    small: ProjectTileSmall,
    medium: ProjectTileMedium,
    large: ProjectTileLarge,
};

const SpacingTypes = {
    1: 'gap-y-1',
    2: 'gap-y-2',
    3: 'gap-y-3',
    4: 'gap-y-4',
    5: 'gap-y-5',
    6: 'gap-y-6',
    7: 'gap-y-7',
};

interface ProjectListProps {
    spacing: keyof typeof SpacingTypes,
    projectRepeater: ProjectRepeaterEntity
}

export interface ProjectRepeaterEntity {
    id: string;
    type: keyof typeof projectTypes;
    projects: ProjectsEntity[];
}

export interface ProjectsEntity {
    id: string;
    title: string;
    heroTitle: string;
    slug: string;
    tags: string[];
    heroImage: {
        gatsbyImageData: IGatsbyImageData
        description: string,
    };
    coverVideo?: VimeoEntity,
    description?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

const ProjectList = ({ projectRepeater, spacing }: ProjectListProps): JSX.Element => {
    const { projects, type } = projectRepeater;

    const styleAsGrid = (type === 'small');

    return (
        <ul className={`blank-list ${styles.ProjectList} ${(styleAsGrid) ? styles.ProjectListGrid : ''} ${SpacingTypes[spacing]}`}>
            {
                projects && projects.map((project) => {
                    const Component = projectTypes[type];
                    return (
                        <Component
                            key={project.id}
                            title={project.title}
                            description={project.description}
                            slug={project.slug}
                            tags={project.tags}
                            image={project.heroImage}
                            coverVideo={project.coverVideo}
                        />
                    );
                })
            }
        </ul>
    );
};

export default ProjectList;
