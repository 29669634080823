import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { VimeoEntity } from '../../../types/contentfulModels/VimeoEntity';
import * as styles from './ProjectTileMedium.module.scss';

import LinkButton from '../../Buttons/LinkButton/LinkButton';
import ImageTile, { ImageTileProps } from '../../ImageTile/ImageTile';
import TagCloud from '../../TagCloud/TagCloud';
import RichTextBlock from '../../RichTextBlock/RichTextBlock';
import VideoBackground from '../../VideoBackground/VideoBackground';

interface ProjectTileMediumProps {
    image: ImageTileProps['imageData'],
    coverVideo?: VimeoEntity,
    title: string,
    slug: string,
    description?: RenderRichTextData<ContentfulRichTextGatsbyReference>,
    tags?: string[],
}

const ProjectTileMedium = ({
    image, title, slug, description, tags, coverVideo
}: ProjectTileMediumProps): JSX.Element => {
    const tileRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    return (
        <li className={`${styles.ProjectTileMedium}`}>
            <div className={styles.ProjectTileMediumBody}>
                <Link to={`/projects/${slug}`} className="space-y-3">
                    <TagCloud tags={tags} />
                    <h3 className="text-subtitle">{title}</h3>
                    { (description) ? <RichTextBlock content={description} spacing="space-y-1" /> : null }
                </Link>
                <LinkButton href={`/projects/${slug}`} label="View project" bordered />
            </div>
            <Link to={`/projects/${slug}`} className={styles.ProjectTileMediumAsset}>
                <div ref={tileRef} className="relative" style={{ aspectRatio: '1.3 / 1' }}>
                    {
                        (coverVideo)
                            ? <VideoBackground videoData={coverVideo} parentRef={tileRef} />
                            : <ImageTile imageData={image} />
                    }
                </div>
            </Link>
        </li>
    );
};

export default ProjectTileMedium;
