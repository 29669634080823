import React, { useRef } from 'react';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import * as styles from './ProjectTileLarge.module.scss';

import { VimeoEntity } from '../../../types/contentfulModels/VimeoEntity';

import ImageTile, { ImageTileProps } from '../../ImageTile/ImageTile';

import LinkButton from '../../Buttons/LinkButton/LinkButton';
import TagCloud from '../../TagCloud/TagCloud';
import VideoBackground from '../../VideoBackground/VideoBackground';

interface ProjectTileLargeProps {
    image: ImageTileProps['imageData'],
    coverVideo?: VimeoEntity,
    title: string,
    slug: string,
    description?: RenderRichTextData<ContentfulRichTextGatsbyReference>,
    tags?: string[]
}

const ProjectTileLarge = ({
    image, title, slug, tags, coverVideo, description
}: ProjectTileLargeProps): JSX.Element => {
    const plainDescription = (description) ? documentToPlainTextString(JSON.parse(description.raw)) : null;

    const ratio = '2';

    const tileRef = useRef() as React.MutableRefObject<HTMLLIElement>;

    return (
        <li ref={tileRef} className={`${styles.ProjectTileLarge}`} style={{ aspectRatio: `auto ${ratio}/1` }}>
            {
                (coverVideo)
                    ? (
                        <VideoBackground
                            videoData={coverVideo}
                            parentRef={tileRef}
                        />
                    )
                    : null
            }
            {
                (!coverVideo && image)
                    ? (
                        <ImageTile
                            imageData={image}
                        />
                    )
                    : null
            }
            <div className={`${styles.ProjectTileLargeInner} img-bg-gradient--to-top`}>
                <div className={`${styles.ProjectTileLargeBody} max-w-0.25`}>
                    <TagCloud tags={tags} />
                    <h3 className="text-subtitle">{title}</h3>
                    { (plainDescription) ? <p className="text-paragraph">{plainDescription}</p> : null }
                    <LinkButton href={`/projects/${slug}`} label="View project" bordered pseudoLink />
                </div>
            </div>
        </li>
    );
};
export default ProjectTileLarge;
