import React from 'react';
import { Link } from 'gatsby';

import * as styles from './ProjectTileSmall.module.scss';

import TagCloud from '../../TagCloud/TagCloud';
import IconArrowRight from '../../Icons/IconArrowRight';
import ImageTile, { ImageTileProps } from '../../ImageTile/ImageTile';

interface ProjectTileSmallProps {
    image: ImageTileProps['imageData'],
    title: string,
    slug: string,
    tags?: string[]
}

const ProjectTileSmall = ({
    image, title, slug, tags
}: ProjectTileSmallProps): JSX.Element => (
    <li className={styles.ProjectTileSmall}>
        <ImageTile
            imageData={image}
            gradient="to-top"
            ratio="portrait"
            childrenPosition="at-bottom"
        >
            <div className={styles.ProjectTileSmallInner}>
                <TagCloud tags={tags} />
                <h3 className="text-heading">{title}</h3>
                <Link to={`/projects/${slug}`} className="flex justify-between items-center w-full pseudo-link">
                    <span className="text-smallcaps text-bronze-400">View project</span>
                    <IconArrowRight stroke="bronze-400" size="32px" />
                </Link>
            </div>
        </ImageTile>
    </li>
);

export default ProjectTileSmall;
